@import "mixins"
.dropdown
  position: relative

  > div:first-of-type
    @include flex
    padding: 5px 0
    border-bottom: 1px solid var(--account_fieldBorder)
    font-size: 12px
    color: rgb(var(--secondaryColor))
    max-width: 124px
    line-height: 13px

    > span
      @include ellipsis

      + i,
      :global(.svg-icon)
        margin-right: 6px
        font-style: normal

      :global(.svg-icon)
        margin-right: 6px
        display: inline-block
        vertical-align: sub

    .selectedCount
      line-height: 14px
      margin-left: 4px
      color: rgb(var(--primaryColor))

    &:after
      content: ""
      min-width: 8px
      width: 8px
      height: 4px
      background-color: var(--icon_base_fill)
      mask-image: var(--icon-dropdown-arrow)
      margin-left: auto

  &:hover > div:first-of-type
    @include pointer

.dropdownList
  position: absolute
  border-radius: var(--base_borderRadius)
  z-index: 99
  width: 100%
  transition: opacity 0.3s linear !important
  overflow: hidden
  max-height: 210px
  background: var(--customDdl_bg)
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3)

  li
    padding: 6px
    border-bottom: 1px solid var(--customDdl_borderBottom)
    border-top: 1px solid var(--customDdl_borderTop)
    @include flex

    > i,
    :global(.svg-icon)
      min-width: 14px
      margin-right: 6px

    &:first-of-type
      border-top: 0

    &:last-of-type
      border-bottom: 0

    &:hover
      @include pointer
      background: rgb(var(--customDdl_activeBg))
      color: rgb(var(--secondaryColor))

    & :global
      .ui-checkbox > i
        margin-right: 6px

  :global(.selected-li)
    background: rgb(var(--customDdl_activeBg))
    color: rgb(var(--secondaryColor))
    cursor: default

  > div > div:first-of-type
    padding-right: 0

  > div > div:nth-child(2)
    border-top-right-radius: 2px
    border-bottom-right-radius: 2px
    background: rgba(0, 0, 0, 0) !important
    border: 0
    width: 4px
    padding: 0
    right: 2px

    > div
      background: rgb(var(--primaryColor))
      opacity: 0.6

.bottom
  margin-top: 2px

.top
  margin-top: -100px

.dropDownContainer
  max-height: 210px

.label
  text-transform: uppercase
  font-size: var(--base_smallFontSize)
  line-height: calc(var(--base_smallFontSize) + 1px)
  color: var(--common_field_labelColor)
  @include flex

.error
  composes: error from "components/base/input-field/input-field.module.sass"

.fieldError
  position: relative

  &.dropdown > div
    border-color: rgb(var(--particularColor2))

.placeholder
  color: var(--common_dropdown_placeholderColor)

.dropdownLoader:global(.loader)
  width: 20px !important
  background: transparent !important

  > span
    font-size: 8px !important
    width: 8px !important
    height: 8px !important
