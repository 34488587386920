@import "mixins"
.scrollContainer
  height: calc(100% - 48px)
  position: relative
  overflow-y: auto
  &::-webkit-scrollbar
    width: 10px

  &::-webkit-scrollbar-thumb
    background: var(--login_closeBtnBg)
    border-left: 6px solid var(--register_bg)
    border-top: 2px solid var(--register_bg)
    border-bottom: 2px solid var(--register_bg)
    border-radius: 0

.register
  background: var(--register_bg)
  border-radius: 8px
  width: 360px
  min-height: 200px
  display: flex
  flex-direction: column
  padding: 0 16px 14px

  :global(.loader)
    left: 0
    top: 0

    > span
      color: var(--login_loaderBg)
      font-size: 18px
      width: 18px
      height: 18px

.signInBtn
  @include bold
  color: var(--register_signInColor)
  text-transform: uppercase
  display: inline-block
  margin-top: 2px
  padding: 0
  background: transparent
  height: auto
  font-size: 11px

.registerTxt
  color: rgb(var(--particularColor))
  @include bold
  display: block
  padding: 10px 0 18px
  line-height: 18px
  font-size: 13px

.header
  @include flex
  height: 48px
  min-height: 48px
  font-size: 20px
  border-bottom: var(--login_headerBorder)
  border-bottom-style: dotted
  color: rgb(var(--secondaryColor))
  @include bold

  > div
    font-size: 12px
    margin-left: auto
    margin-right: 16px
    text-align: right

    > span
      display: block

.signupForm
  display: flex
  align-content: flex-start
  flex-wrap: wrap

  :global(.required)
    &:before
      content: ""
      position: absolute
      right: 0
      top: 0
      z-index: 1
      height: 16px
      width: 16px
      background-color: rgb(var(--particularColor2))
      border: inherit
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%)
      transform: rotate(180deg)
      border-radius: 0 0 0 var(--base_borderRadius)

    &:after
      content: "*"
      color: rgb(var(--secondaryColor))
      position: absolute
      right: 3px
      top: 2px
      font-size: 11px
      z-index: 2

.formSubmit
  @include flex
  flex-direction: column
  width: 100%

  button
    width: 100%
    max-width: 100%
    border-radius: var(--base_borderRadius)
    height: var(--field_height)
    font-size: 12px
    margin-top: 10px

    :global(.loader) > span
      color: rgba(var(--secondaryColor), 0.8)
      font-size: 12px
      width: 12px
      height: 12px
